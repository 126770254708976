import { createSlice } from "@reduxjs/toolkit";

export const userProfileSlice = createSlice({
  name: "user",
  initialState: {
    data: null, // Зберігає дані обраного користувача
    showUserProfile: false, // Контролює відображення компонента User
  },
  reducers: {
    toggleUserProfile: (state) => {
        console.log('toggleUser');
      state.showUserProfile = !state.showUserProfile; // Перемикає стан showAuthorize
    },
    setUser: (state, action) => {        
      state.data = action.payload; // Зберігає дані користувача      
    },
  },
});

export const { toggleUserProfile, setUser } =
userProfileSlice.actions;
export default userProfileSlice.reducer;
