import { useRef, useState, useMemo } from 'react';
import Webcam from 'react-webcam';

import { b64toBlob } from 'utils/converter';
import { apiRequest } from 'utils/api';

import './camera-viewer.css';
import useRecordHandler from 'hooks/useRecordHandler';

export default function CameraViewer({ setCameraOn }) {
    const camera = useRef();
    const { isRecording, isPaused, seconds, start, pause, resume, stop, video, clearVideo } =
        useRecordHandler(camera);

    const [mode, setMode] = useState('photo');

    const [photo, setPhoto] = useState(null);

    const [facingMode, setFacingMode] = useState('user');
    const videoConstraints = useMemo(() => {
        return {
            width: 1080,
            height: 1920,
            facingMode,
            aspectRatio: 9 / 16,
        };
    }, [facingMode]);

    return (
        <section className='camera-viewer'>
            {useMemo(
                () =>
                    photo || video() ? (
                        <Result
                            result={photo ? photo : video()}
                            setResult={photo ? setPhoto : clearVideo}
                            isPhoto={mode == 'photo'}
                            setCameraOn={setCameraOn}
                        />
                    ) : (
                        <></>
                    ),
                [photo, video]
            )}
            {useMemo(
                () =>
                    isRecording || isPaused ? (
                        <></>
                    ) : (
                        <div className='selector'>
                            <div
                                className='photo'
                                onClick={() => setMode('photo')}
                                style={{
                                    borderBottomColor: mode == 'photo' ? 'white' : 'transparent',
                                    opacity: mode == 'photo' ? '1' : '30%',
                                }}
                            >
                                Фото
                            </div>
                            <div
                                className='video'
                                onClick={() => setMode('video')}
                                style={{
                                    borderBottomColor: mode == 'video' ? 'white' : 'transparent',
                                    opacity: mode == 'video' ? '1' : '30%',
                                }}
                            >
                                Відео
                            </div>
                        </div>
                    ),
                [isRecording, isPaused, mode]
            )}

            <Webcam
                audio={true}
                muted={true}
                ref={camera}
                width={1080}
                height={1920}
                screenshotFormat='image/jpg'
                videoConstraints={videoConstraints}
                disablePictureInPicture={true}
            />
            <div className='controller'>
                {useMemo(
                    () =>
                        mode == 'video' ? (
                            <div className='timer'>
                                <div
                                    className='line'
                                    style={{
                                        background: `linear-gradient(90deg, white ${
                                            ((10 - seconds) / 10) * 100
                                        }%, #b8b8b8a6 0%)`,
                                    }}
                                ></div>
                                <h2>{`${10 - Math.floor(seconds)} s`}</h2>
                            </div>
                        ) : (
                            <></>
                        ),
                    [mode, seconds]
                )}
                <button
                    className='close-capture'
                    onClick={() => {
                        if (isPaused) resume();
                        else if (isRecording) pause();
                        else setCameraOn(false);
                    }}
                >
                    {isRecording || isPaused ? (
                        <img src='/svg/pause-video.svg' alt='pause' />
                    ) : (
                        <img src='/svg/close-camera.svg' alt='close-camera' />
                    )}
                </button>
                <button
                    className='take-photo'
                    onClick={() => {
                        if (mode == 'photo') setPhoto(camera.current.getScreenshot());
                        if (mode == 'video') isRecording || isPaused ? stop() : start();
                    }}
                    style={{
                        background: mode == 'photo' ? 'none' : 'red',
                        borderRadius: isRecording || isPaused ? '25%' : '50%',
                    }}
                ></button>
                <button
                    className='change-camera'
                    onClick={() => setFacingMode(facingMode == 'user' ? 'environment' : 'user')}
                >
                    <img src='/svg/change-camera.svg' alt='close-camera' />
                </button>
            </div>
        </section>
    );
}

function Result({ result, setResult, isPhoto, setCameraOn }) {
    const [comment, setComment] = useState('');

    return (
        <section className='result'>
            {isPhoto ? (
                <img className='photo-result' src={result} />
            ) : (
                <video
                    className='video-result'
                    src={URL.createObjectURL(result)}
                    loop
                    autoPlay={true}
                />
            )}
            <div className='splash'></div>
            <div className='top-nav'>
                <img src='/svg/trash-icon.svg' alt='remove' onClick={() => setResult(null)} />

                <button
                    onClick={async () => {
                        const blob = isPhoto ? b64toBlob(result) : result;

                        const file = new File(
                            [blob],
                            `msr-${new Date().toISOString().replace(/:|\./g, '-')}.${
                                isPhoto ? 'jpg' : 'webm'
                            }`,
                            {
                                type: isPhoto ? 'image/jpg' : blob.type,
                            }
                        );
                        await apiRequest(`save_${isPhoto ? 'img' : 'video'}.php`, 'POST', {
                            description: comment,
                            type: isPhoto ? 'photo' : 'video',
                            'video-filename': file.name,
                            'video-blob': file,
                        });

                        setCameraOn(false);
                    }}
                >
                    Опубліковати
                </button>
            </div>
            <div className='comment'>
                <h5>Додайте коментар</h5>
                <textarea
                    name='comment'
                    id='comment'
                    style={{ resize: 'none' }}
                    onInput={(event) => setComment(event.target.value)}
                ></textarea>
            </div>
        </section>
    );
}
